var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t(
        "default",
        [
          _vm._v(
            " " +
              _vm._s(_vm.day) +
              ":" +
              _vm._s(_vm.hour) +
              ":" +
              _vm._s(_vm.minute) +
              ":" +
              _vm._s(_vm.second) +
              " "
          )
        ],
        {
          data: {
            day: _vm.day,
            hour: _vm.hour,
            minute: _vm.minute,
            second: _vm.second
          }
        }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }