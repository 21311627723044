import "core-js/modules/es.date.now";import "core-js/modules/es.date.to-string";import "core-js/modules/es.parse-int";import "core-js/modules/web.timers"; //
//
//
//
//
//
//
//

/* 倒计时 */
export default {
  props: {
    date: {
      "default": function _default() {
        return new Date();
      } },

    autoStart: {
      type: Boolean,
      "default": true } },


  data: function data() {
    return {
      hour: '00',
      minute: '00',
      second: '00',
      day: '00',
      interval: null,
      count: new Date(this.date).getTime() - Date.now() };

  },
  mounted: function mounted() {
    console.log(this.autoStart);
    if (this.autoStart) {
      this.start();
    }
  },
  watch: {
    date: function date(v) {
      this.start();
    } },

  beforeUnmount: function beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    start: function start() {var _this = this;
      clearInterval(this.interval);
      var count = new Date(this.date).getTime() - Date.now();

      if (count <= 0) {
        return;
      }
      this.interval = setInterval(function () {
        count = count - 1000;
        if (count <= 0) {
          _this.second = '00';
          clearInterval(_this.interval);
          _this.timeDown();
          return;
        }
        _this.day = Math.floor(count / (24 * 3600 * 1000));
        if (_this.day < 10) {
          _this.day = '0' + _this.day;
        }
        // 剩余的秒数
        var leave1 = count % (24 * 3600 * 1000);

        _this.hour = String(parseInt(leave1 / (60 * 60 * 1000)));
        if (_this.hour < 10) {
          _this.hour = '0' + _this.hour;
        }
        var n = leave1 % (60 * 60 * 1000);

        _this.minute = String(parseInt(n / (60 * 1000)));
        if (_this.minute < 10) {
          _this.minute = '0' + _this.minute;
        }
        var n2 = n % (60 * 1000);

        _this.second = String(parseInt(n2 / 1000));
        if (_this.second < 10) {
          _this.second = '0' + _this.second;
        }
      }, 1000);
    },
    timeDown: function timeDown() {
      this.$emit('timeDown');
    } } };